.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.mt-24px {
	margin-top: 24px;
}

.mt-16px {
	margin-top: 16px;
}

.mt-32px {
	margin-top: 32px;
}

.ms-8px {
	margin-left: 8px;
}

.mt-8px {
	margin-top: 8px;
}

.mt-48px {
	margin-top: 48px;
}

