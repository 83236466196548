.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.min-w-320px {
	min-width: 320px !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.w-30 {
	width: 30% !important;
}

.w-150px {
	width: 150px !important;
}

.w-48px {
	width: 48px !important;
}

.max-w-1000px {
	max-width: 1000px !important;
}

.w-156px {
	width: 156px !important;
}

