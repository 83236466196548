$btn-white-space: nowrap;
$primary: #E1AA00;
$border-radius: 0.25rem;
$font-family: var(--bs-body-font-family);
$theme-colors: (
  
	'accent': #9E9E9E,
	'primary': #E1AA00,
	'primary-dark': #463223,
	'primary-light': #FAC30A,
	'primary-text': #212121,
	'secondary-text': #757575,
	'text-on-primary': #ffffff,
	'text-on-accent': #000000,
	'background': #ffffff,
	'oauth_lighten': #ffffffd9,
) !default;
