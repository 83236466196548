.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}


@font-face {
	font-family: renner;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-renner {
font-family: renner;
}

@font-face {
	font-family: gantaribold;
	src: url('../../assets/fonts/Gantari-Bold.ttf');
}

.ff-gantaribold {
font-family: gantaribold;
}

@font-face {
	font-family: gantariregular;
	src: url('../../assets/fonts/Gantari-Regular.ttf');
}

.ff-gantariregular {
font-family: gantariregular;
}



.f-12px {
	font-size: 12px;
}


